// @import 'https://static.gui.axkans.org/portal/themes/base/styles/style.css';
// @import 'https://static.dev-gui.axkans.org/portal/themes/blue-gray/styles/theme.css';
@import 'https://vw.detonador.axkans.net/habitatmx/gassets/styles/theme/base/style.css';
@import 'https://vw.detonador.axkans.net/habitatmx/gassets/styles/theme/blue-gray/theme.css';

* {
    font-size: .9rem;
}
body {
    overflow: hidden;
}

@media screen and (max-width: 430px) {
    * {
        font-size: .9rem;
    }
}



